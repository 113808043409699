import { CognitoAuthProvider } from 'ra-auth-cognito'

const authProvider = CognitoAuthProvider({
  mode: 'oauth',
  clientId: 'CLIENT_ID_PLACEHOLDER',
  userPoolId: 'USER_POOL_ID_PLACEHOLDER',
  //clientId: '4qk1met0snvlp7tpim4n9vb39i',
  //userPoolId: 'eu-west-1_FDGDApBYL',
  redirect_uri: window.location.origin,
  hostedUIUrl: 'https://customer-account-dev-pool-ca-706-admin.auth.eu-west-1.amazoncognito.com',
  scope: ['openid', 'aws.cognito.signin.user.admin'],
})

if (authProvider.handleCallback) {
  console.log('handle callback conditions passed')
  if (window.location.href.includes('access_token')) {
    console.log('href includes access_token')
    authProvider.handleCallback().then(() => {
      window.location.href = ''
    })
  }
}

export const getAccessToken = async () => {
  if (!authProvider.getIdentity) {
    throw new Error('User is not authenticated')
  }

  const identity = await authProvider.getIdentity()
  return identity.cognitoUser.signInUserSession.getAccessToken().getJwtToken()
}

export default authProvider
