import type { ApiPlatformAdminDataProvider } from '@api-platform/admin'
import { ApiPlatformAdminUpdateParams, hydraDataProvider, fetchHydra as baseFetchHydra } from '@api-platform/admin'
import { UpdateResult } from 'ra-core'
import { HttpError } from 'react-admin'
import { getAccessToken } from '../authProvider/AuthProvider'

const getChangedFields = (currentData: any, previousData: any) => {
  const changedFields: any = {}

  const compareFields = (current: any, previous: any, path: string[] = []) => {
    let hasChanges = false
    const nestedChanges: any = {}

    for (const key in current) {
      if (key === 'id') continue

      const currentPath = [...path, key]
      const currentValue = current[key]
      const previousValue = previous ? previous[key] : undefined

      if (typeof currentValue === 'object' && currentValue !== null && !Array.isArray(currentValue)) {
        const nestedResult = compareFields(currentValue, previousValue, currentPath)
        if (Object.keys(nestedResult).length > 0) {
          nestedChanges[key] = { ...currentValue, ...nestedResult }
          delete nestedChanges[key].id
          hasChanges = true
        }
      } else if (
        (currentValue !== previousValue && currentValue !== null) ||
        (currentValue === null && previousValue !== undefined)
      ) {
        //workaround for always complete data in contactAddress and hqAddress. In customer object, null values are omitted.
        if (
          (currentPath.includes('contactAddress') || currentPath.includes('hqAddress')) &&
          currentValue === null &&
          previousValue === null
        ) {
          continue
        }
        console.log('Changed field:', currentPath, currentValue, previousValue)
        nestedChanges[key] = currentValue
        hasChanges = true
      }
    }

    if (hasChanges) {
      return nestedChanges
    } else {
      return {}
    }
  }

  Object.assign(changedFields, compareFields(currentData, previousData))
  return changedFields
}

const mergeNullValues = (json: any, changedData: any): void => {
  for (const key in changedData) {
    if (changedData[key] === null) {
      json[key] = null
    } else if (typeof changedData[key] === 'object' && changedData[key] !== null && !Array.isArray(changedData[key])) {
      if (!json[key]) {
        json[key] = {}
      }
      mergeNullValues(json[key], changedData[key])
    }
  }
}

const customDataProvider = (entrypoint: string): ApiPlatformAdminDataProvider => {
  const fetchHydra = async (url: URL, options: any = {}) => {
    const accessToken = await getAccessToken()
    return baseFetchHydra(url, {
      ...options,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  }

  return {
    ...hydraDataProvider({ entrypoint: entrypoint, httpClient: fetchHydra }),
    update: async (resource: string, params: ApiPlatformAdminUpdateParams): Promise<UpdateResult> => {
      console.log(params)
      const { data, previousData } = params
      const changedData = getChangedFields(data, previousData)

      if (!data.hasOwnProperty('originId')) {
        console.error('Hydra format data problem for update! OriginId is not defined in data:', data)
        throw new Error(
          'Update error: There is a unexpected problem with the data for update. Contact the administrator.'
        )
      }

      console.log('Provide customer update, all data:', data, 'prepared data in request:', changedData)
      const accessToken = await getAccessToken()
      const response = await fetch(new URL(`${entrypoint}/${resource}/${data.originId}`), {
        method: 'PATCH',
        body: JSON.stringify(changedData),
        headers: new Headers({
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${accessToken}`,
        }),
      })
      const status = response.status
      const json = await response.json()
      const normalizedId = json['@id']
      console.log('result after update:', json)

      if (status < 200 || status >= 300) {
        const violations: { [key: string]: string[] } = {}
        if (json['violations']) {
          json['violations'].forEach((violation: any) => {
            if (violation.propertyPath !== '') {
              violations[violation.propertyPath] = violation.message
            }
          })
        }

        throw new HttpError('An error occurred while updating the data.', status, {
          errors: {
            root: { serverError: json['hydra:description'] },
            ...violations,
          },
        })
      }

      mergeNullValues(json, changedData)

      return { data: { ...json, id: normalizedId } }
    },
  }
}

export default customDataProvider
